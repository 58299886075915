<template>
  <div class="shell-box">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/myHome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          path: `/routerPolicyManagement/${this.$route.meta.policyRoute}/policyManagement`,
        }"
        >保单列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>保单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="form">
      <policyDetails
        :detailData="policyDetail"
        :updateDetail="updateRecordDetail"
        :auditProcessList="auditProcessList"
        :permissions="policyDetailPermissions"
      />
    </div>
    <div class="operating-box">
      <el-button size="mini" @click="handleBack">返回</el-button>
      <!-- <el-button size="mini"
        type="primary"
        plain
        @click="handleCreateCorrectionOrder" v-if="(policyDetail.approvalStatus == 5 || policyDetail.approvalStatus == 3)">保单批改</el-button> -->
      <el-button
        size="mini"
        type="primary"
        plain
        @click="handleEdit"
        v-if="
          policyDetail.approvalStatus != 2 &&
          hasPerms(`M20-edit_${$route.meta.policyType}`)
        "
        >编辑</el-button
      >
      <el-button
        size="mini"
        type="primary"
        plain
        @click="handleRevocation"
        v-if="
          policyDetail.approvalStatus == 2 &&
          hasPerms(`M20-recall_${$route.meta.policyType}`)
        "
        >撤回</el-button
      >
      <!-- <el-button
        size="mini"
        type="primary"
        @click="showUpload = true"
        v-if="
          $route.meta.policyType == 1 &&
          hasPerms(`M20-checkDoc_${$route.meta.policyType}`)
        "
        >查看附件</el-button
      > -->
      <el-button
        size="mini"
        type="primary"
        @click="downloadAllAttachment"
        v-if="
          $route.meta.policyType != 1 &&
          hasPerms(`M20-downloadDoc_${$route.meta.policyType}`)
        "
        >下载全部附件</el-button
      >
    </div>
    <DragMultipleUpload
      :show.sync="showUpload"
      :fileSzie="10"
      :dataEcho="dataEcho"
      :canEdit="false"
    ></DragMultipleUpload>
  </div>
</template>

<script>
import policyDetails from "@/views/policyManagement/components/details.vue";
import {
  getPolicyDetail,
  getUpdateRecord,
  policyProcessGetNodeRecord,
  policyProcessRevoke,
} from "@/api/policy";
import DragMultipleUpload from "@/components/DragMultipleUpload";
import { hasPerms, dataPermissions } from "@/utils/auth";
import { downloadZIP } from "@/utils/index";
export default {
  components: { policyDetails, DragMultipleUpload },
  data() {
    return {
      hasPerms: hasPerms,
      policyDetail: {
        attachments: {
          yyzz: [],
          kpxx: [],
          qtxx: [],
          dzbd: [],
        },
      },
      updateRecordDetail: [],
      showUpload: false,
      dataEcho: {},

      policyDetailPermissions: {
        // deductibleTwo: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, 'deductibleTwo'),
        // "desc": "扩展不计免赔2（绝对免赔1000元）",
        endDate: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "endDate"
        ),
        // "desc": "到期时间",
        policyNo: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "policyNo"
        ),
        // "desc": "保单号",
        // absoluteExemptionPerAccident: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, 'absoluteExemptionPerAccident'),
        // "desc": "每次事故绝对免陪额",
        fromSourceType: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "fromSourceType"
        ),
        // "desc": "保单来源",
        policyState: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "policyState"
        ),
        // "desc": "保单状态",
        province: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "province"
        ),
        // "desc": "所属省份",
        // renewalState: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, 'renewalState'),
        // "desc": "续保状态",
        acceptCompanyList: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "acceptCompanyList"
        ),
        // "desc": "承保公司",
        specialAgreement: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "specialAgreement"
        ),
        // "desc": "特别约定",
        salesmanName: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "salesmanName"
        ),
        // "desc": "业务员",
        brandName: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "brand"
        ),
        // "desc": "设备品牌",
        salesmanId: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "salesmanName"
        ),
        // "desc": "业务员",
        beneficiaryList: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "beneficiaryList"
        ),
        // "desc": "受益人",
        policyHolderList: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "policyHolderList"
        ),
        // "desc": "投保人",
        schemeName: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "schemeName"
        ),
        // "desc": "方案名称",
        agentName: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "agentName"
        ),
        // "desc": "代理人",
        ep: dataPermissions(`M20_${this.$route.meta.policyType}-View`, "ep"),
        // "desc": "电子保单",
        policyPlanList: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "policyPlanList"
        ),
        // "desc": "保障计划",
        insuranceName: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "insuranceTypeName"
        ),
        // "desc": "险种名称",
        absoluteDeductible: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "absoluteDeductible"
        ),
        // "desc": "扩展不计免赔1",
        policyInsuredList: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "insured"
        ),
        // "desc": "被保险人",
        projectName: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "projectName"
        ),
        // "desc": "项目名称",
        startDate: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "startDate"
        ),
        // "desc": "起保时间",
        status: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "policyState"
        ),
        // "desc": "保单状态",
        frameNo: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "frameNo"
        ),
        // "desc": "车架号",
        agentId: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "agentName"
        ),
        // "desc": "代理人",
        applicantMode: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "applicantMode"
        ),
        // "desc": "投保方式",
        lastPolicyNo: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "lastPolicyNo"
        ),
        // "desc": "上一年保单号",
        insured: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "insured"
        ),
        // "desc": "被保险人",
        city: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "city"
        ),
        // "desc": "所属地区",
        equipmentType: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "equipmentType"
        ),
        // "desc": "设备型号",
        applicantType: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "applicantType"
        ),
        // "desc": "投保类型",
        extensionClause: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "extensionClause"
        ),
        // "desc": "免费扩展条款",
        insuranceCompany: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "insuranceCompany"
        ),
        // "desc": "保险公司",
        applicantStore: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "applicantStore"
        ),
        // "desc": "投保门店",
        absoluteDeductible3: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "absoluteDeductible3"
        ),
        // "desc": "未扩展不计免赔",
        absoluteDeductible2: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "absoluteDeductible2"
        ),
        // "desc": "扩展不计免赔2",
        agentNo: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "agentNo"
        ),
        // "desc": "代理人编码",
        totalPremium: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "totalPremium"
        ),
        // "desc": "总保费",
        updateTime: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "updateTime"
        ),
        // "desc": "更新时间",
        projectRemark: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "projectRemark"
        ),
        // "desc": "项目备注",
        createTime: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "createTime"
        ),
        // "desc": "创建时间",
        fromSourceTypeName: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "fromSourceTypeName"
        ),
        // "desc": "保单来源",
        policySubjectList: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "policySubjectList"
        ),
        // "desc": "标的信息",
        insuranceTypeName: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "insuranceTypeName"
        ),
        // "desc": "险种名称",
        enquiryNo: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "enquiryNo"
        ),
        // "desc": "新询价单号",
        policyHolder: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "policyHolder"
        ),
        // "desc": "投保人",
        recorder: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "recorder"
        ),
        // "desc": "录单员",

        insuranceOfDate: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "insuranceOfDate"
        ),
        // 投保日期

        upstreamAgent: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "upstreamAgent"
        ),
        // 业务代理人
        specialVersion: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "specialVersion"
        ),
        // "desc": "特别说明",
        bindUser: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "bindUser"
        ),
        // 绑定客户
        sourceChannel: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "sourceChannel"
        ),
        // 业务渠道
        insuranceTypeName: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "insuranceTypeName"
        ),
        // 险种类型
        createTime: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "createTime"
        ),
        // 创建时间
      },
      auditProcessList: [],
    };
  },
  created() {
    this.getDetail();
  },
  activated() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      getPolicyDetail({
        id: this.$route.params.id,
      })
        .then((res) => {
          //   if (res.data.policyPlanList && res.data.policyPlanList.length) {
          //     res.data.policyPlanList = res.data.policyPlanList.map(val => {
          //       val.rateType ? "" : val.rateType = 1;
          //       return val;
          //     });
          //   }
          this.policyDetail = res.data;
          let dataBackShow = [];
          let idInd = 1;
          res.data.attachments
            ? Object.keys(res.data.attachments).forEach(function (key) {
                let type = (key + "").split("-");
                dataBackShow.push({
                  type: type[0] == "qt" ? undefined : type[0],
                  selectValue: Number(type[1] || 0),
                  urls: res.data.attachments[key].map((val) => {
                    idInd++;
                    return {
                      suffix: val.fileType,
                      name: val.fileName,
                      url: val.url,
                      id: idInd,
                    };
                  }),
                });
              })
            : "";
          this.dataEcho = dataBackShow;
          policyProcessGetNodeRecord({
            bussId: res.data.id,
            processType: 2,
          }).then((res2) => {
            this.auditProcessList = res2.data;
          });
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
      getUpdateRecord({
        policyId: this.$route.params.id,
      })
        .then((res) => {
          this.updateRecordDetail = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleEdit() {
      this.$router.push({
        path:
          `/routerPolicyManagement/${this.$route.meta.policyRoute}/policyEdit/` +
          this.policyDetail.id,
      });
    },
    handleCreateCorrectionOrder(e) {
      this.$router.push({
        path:
          "/routerPolicyManagement/createCorrectionOrder/" +
          this.$route.params.id,
      });
    },
    handleRevocation(item) {
      this.$confirm("是否确认撤回？", "撤销", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          policyProcessRevoke({
            processType: 2,
            bussId: this.policyDetail.id,
            processId: this.policyDetail.processId,
          }).then((res) => {
            this.getDetail();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消撤回",
          });
        });
    },
    downloadAllAttachment() {
      const loading = this.$loading({
        lock: true,
        text: "下载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let arr = [];
      this.dataEcho.forEach((item) => {
        item.urls.forEach((urlitem) => {
          if (urlitem.url) {
            arr.push({
              name: `${urlitem.name}.${urlitem.suffix}`,
              url: urlitem.url,
            });
          }
        });
      });
      if (arr.length) {
        downloadZIP(arr, "附件")
          .then((res) => {
            loading.close();
          })
          .catch((err) => {
            loading.close();
          });
      } else {
        this.$message.warning("暂无可下载的附件！");
        loading.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .form {
    flex: 1;
    overflow: hidden;
  }
  .breadcrumb {
    padding: 10px 0;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }
  .operating-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 196px);
    height: 50px;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: 99;
  }
}
</style>
